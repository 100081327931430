import React from "react";
import { useTranslation } from "react-i18next";
import useFormattedDate from "../hooks/useFormattedDate";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
// import DecorPng from "../assets/decor-bottom.png";
import FoundersImage from "../assets/en/nightly-footer-founders.png";
import { device } from "../breakpoints";
import SignUpForm from "./customers/SignUpForm.jsx";
import fbIcon from "../assets/fb-social-icon.png";
import instagramIcon from "../assets/instagram-social-icon.png";
import tiktokIcon from "../assets/tiktok-social-icon.png";
import linkedinIcon from "../assets/linkedin-social-icon.png";
import { ReactComponent as CircleFooterDecorMobile } from "../assets/circles-footer-mobile.svg";
import { ReactComponent as CircleFooterDecorPC } from "../assets/circles-footer.svg";

const Footer = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // Scrolls to the top of the page
  };
  const targetDate = "2025-02-28T00:00:00";
  const formattedDate = useFormattedDate(targetDate);

  return (
    <FooterWrapper>
      <FooterSection>
        <CircleDecorationMobile />
        <CircleDecorationPC />
        <Content>
          <FoundersImg src={FoundersImage} alt="Founders" />
          <Title>
            {t("footer_section.footer_title.0")}{" "}
            <span>{t("footer_section.footer_title.1")}</span>
          </Title>
          <JoinText>
            {t("footer_section.footer_subtitle", { targetDate: formattedDate })}
          </JoinText>
          <SignUpForm
            inputFocusClass="bottom"
            buttonColor="#1D1D00"
            textColor="black"
            borderColor="#939182"
            colorr="#801D1D"
            focusBorderColor="#1D1D00"
            submitButtonColor="#1D1D00"
            submitButtonHoverColor="black"
            buttonTextColor="#e9e928"
          />
        </Content>
        <Decor>{/* <DecorImg src={DecorPng} /> */}</Decor>
      </FooterSection>
      <SocialSection>
        <SocialContent>
          <SocialText>{t("footer_section.social_title")}</SocialText>
          <SocialIcons>
            <SocialIcon
              href="https://www.linkedin.com/company/nightlytech"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIconImg src={linkedinIcon} alt="LinkedIn" />
            </SocialIcon>
            <SocialIcon
              href="https://www.tiktok.com/@nightly.dot.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIconImg src={tiktokIcon} alt="TikTok" />
            </SocialIcon>
            <SocialIcon
              href="https://www.instagram.com/nightly.dot.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIconImg src={instagramIcon} alt="Instagram" />
            </SocialIcon>
            <SocialIcon
              href="https://www.facebook.com/groups/nightly.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIconImg src={fbIcon} alt="Facebook" />
            </SocialIcon>
          </SocialIcons>
        </SocialContent>
        <Rules>
          <RulesTitle>{t("footer_section.rules_title")}</RulesTitle>
          <TermsLinks>
            <RuleLink onClick={() => handleNavigation("/terms-of-use")}>
              {t("footer_section.terms")}
            </RuleLink>
            <RuleLink onClick={() => handleNavigation("/privacy-policy")}>
              {t("footer_section.privacy_policy")}
            </RuleLink>
          </TermsLinks>
        </Rules>
      </SocialSection>
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const FooterSection = styled.section`
  background-color: #eaea29;
  text-align: center;
  padding: 120px 20px;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
`;

const CircleDecorationMobile = styled(CircleFooterDecorMobile)`
  position: absolute;
  bottom: -52%;
  left: 50%;
  transform: translateX(-52%);
  width: 215%;
  height: 205%;
  z-index: 0;
  display: block;

  @media ${device.pc} {
    display: none;
  }
`;
const CircleDecorationPC = styled(CircleFooterDecorPC)`
  position: absolute;
  bottom: -30%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: auto;
  z-index: 0;
  display: none;

  @media ${device.pc} {
    display: block;
  }
`;

const Content = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  color: black;
  position: relative;
  z-index: 1;
`;

const FoundersImg = styled.img`
  width: 80%;
  margin-bottom: 20px;
  @media ${device.pc} {
    width: 30%;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
  :lang(he) {
    margin: 5px auto;
    max-width: 310px;
    text-align: center;
  }
  @media ${device.pc} {
    line-height: 1.3em;
    font-size: 3.3rem;
    text-align: center;
    margin: 0 auto;
    /* max-width: 582px; */
    :lang(he) {
      margin: 0 auto;
      line-height: 1.3em;
      font-size: 3.5rem;
      max-width: 700px;
      /* max-width: 500px; */
    }
    span {
      padding: 1px 8px;
      &::before {
        content: "";
        display: block;
      }
      :lang(he) {
        padding: 1px 0px;
        &::before {
          content: "";
          display: block;
        }
      }
    }
  }
`;

const JoinText = styled.p`
  font-size: 1.2rem;
  margin: 20px auto;
  text-align: center;
  max-width: 369px;
  @media ${device.pc} {
    max-width: 500px;
  }
`;

const Decor = styled.div`
  margin-top: 20px;
`;

// const DecorImg = styled.img`
//   width: 100%;
//   @media ${device.pc} {
//     width: 50%;
//   }
// `;

const SocialSection = styled.div`
  background-color: #1c1c17;
  /* color: white; */
  /* padding: 40px 0; */
  padding-top: 35px;
  padding-bottom: 45px;
  width: 100%;
  border-top: 2px solid #c9c6be;
  @media ${device.pc} {
    padding-top: 45px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: space-between;
    justify-content: left;
    margin: 0 auto;

    :lang(he) {
      justify-content: right;
    }
  }
`;

const SocialContent = styled.div`
  max-width: 1000px;
  margin: 0 auto 3px 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  :lang(he) {
    margin-right: 1.5rem;
    /* margin-right: 15rem; */
    align-items: right;
    /* background-color: yellow; */
  }
  @media ${device.pc} {
    align-items: flex-start;
    margin: 0 6rem;
    align-items: left;
    margin-left: 15rem;

    :lang(he) {
      /* margin-right: 1.5rem; */
      margin-right: 15rem;
      align-items: right;
      /* background-color: yellow; */
    }
  }
`;

const SocialText = styled.p`
  font-size: 1.35rem;
  margin-bottom: 20px;
  margin-left: 3px;
  font-weight: 400;
  color: #fff;
  text-align: left;
  @media ${device.pc} {
    margin-left: 0;
    font-size: 1.32rem;
    text-align: center;
  }
  :lang(he) {
    margin-right: 3px;
    text-align: right;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: left;
  gap: 40px;
  @media ${device.pc} {
    gap: 20px;
  }
  :lang(he) {
    justify-content: right;
  }
`;

// const SocialIcon = styled.a`
//   color: white;
//   font-size: 24px;
//   &:hover {
//     opacity: 0.8;
//   }
// `;

const SocialIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%; // Make it circular
  background-color: #333; // Add a background color
  transition: transform 0.3s ease, filter 0.3s ease, opacity 0.3s ease;

  &:hover {
    transform: scale(1.1);
    filter: drop-shadow(0px 0px 7px #383731);
    /* filter: brightness(0) drop-shadow(0px 0px 4px #e9e928); */
    opacity: 1;
  }
`;

const SocialIconImg = styled.img`
  width: 60%;
  height: 60%;
  object-fit: contain;

  @media ${device.pc} {
    width: 55%;
    height: 55%;
    transition: transform 0.3s ease, filter 0.3s ease, opacity 0.3s ease;
    &:hover {
      transform: scale(1.1);
      filter: brightness(0) drop-shadow(0px 0px 7px #fffff);
      opacity: 1;
    }
  }
`;

const Rules = styled.div`
  margin-top: 2rem;
  margin-left: 1.5rem;
  :lang(he) {
    margin-right: 1.5rem;
  }
  @media ${device.pc} {
    margin-top: 0;
    margin-left: 0;
    margin: 0 6rem;
    margin-left: 5rem;

    :lang(he) {
      margin-right: -5rem;
    }
  }
`;

const RulesTitle = styled.p`
  font-size: 1.35rem;
  margin-bottom: 20px;
  margin-left: 3px;
  font-weight: 400;
  color: #fff;
  text-align: left;
  @media ${device.pc} {
    font-size: 1.32rem;
  }
  :lang(he) {
    margin-right: 3px;
    text-align: right;
  }
`;

const TermsLinks = styled.div`
  color: #fff;
  text-align: left;
  font-size: 14px;
  @media ${device.pc} {
  }
  :lang(he) {
    text-align: right;
    font-size: 14px;
  }
`;

const RuleLink = styled.a`
  cursor: pointer;
  color: #e6e2da;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.65rem;
  margin-left: 3px;
  &:hover {
    text-decoration: underline;
    color: white;
  }
  :lang(he) {
    margin-right: 3px;
  }
`;
