import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "he"],
    fallbackLng: "en",
    detection: {
      order: [
        "navigator",
        "cookie",
        "htmlTag",
        "path",
        "localStorage",
        "subdomain",
      ],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    // react: { useSuspense: false },
  });

// Set the lang attribute dynamically based on detected language
i18n.on("languageChanged", (lng) => {
  document.documentElement.lang = lng;
});

// Initial setting of lang attribute
// document.documentElement.lang = i18n.language || "en";
document.documentElement.lang = i18n.language;

const loadingMarkup = (
  <div
    className="lds-spinner"
    style={{ marginTop: "10em", textAlign: "center" }}
  >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);
